import ApplicationController from './application_controller'
import Param from '../packs/param';

export default class extends ApplicationController {

  static targets = [ "learnunit" ]
  static values = { journeyid: String }

  connect() {
    // console.log('connected to learn-unit-select')
    this.journeyContainer = $("#journey_content_container")
    this.learnunitContainer = $("#learnunit_content_container")
    super.connect()
    this.param = new Param(this.getBaseUrl());

    /* check if we have a journey_content_container
       if so, we have to set the background color to white
       can't be made by css, as it is a css attribute of body tag
    */
    const journeyContentContainer = document.getElementById('journey_content_container');
    if (journeyContentContainer) {
      $('body').css('background-color', 'white');
    }
    
  }
  
  ////
  // will call a reflex given in the corresponding learnunitTarget or just display the journey's representation
  ////
  display_info(event) {
    // console.log("show extra information")
    if (this.hasLearnunitTarget) { // we have to call the referenced Reflex
      var linkElement = this.learnunitTarget
      this.stimulate(`Learnunit#show_authoring_type`, linkElement)
      this.journeyContainer.addClass('hidden')
      this.learnunitContainer.removeClass('hidden')
    } else { 
      // console.log("Sorry, no learnunit")
      this.learnunitContainer.addClass('hidden')
      this.journeyContainer.removeClass('hidden')
    }
  }
  
  /**
   * Displays an consumable by checking if there is a referenced learnunit target. 
   * If there is, it calls the "Learnunit#show_consumable_type" reflex with the link element as the argument and hides the journey container while showing the learnunit container. 
   * If there is no learnunit target, it hides the learnunit container and shows the journey container.
   *
   * @param {Object} event - The event to be displayed.
   */
  display_consumable(event) {
    if (this.hasLearnunitTarget) { // we have to call the referenced Reflex
      var linkElement = this.learnunitTarget
      this.stimulate(`Learnunit#show_consumable_type`, linkElement)
      this.journeyContainer.addClass('hidden')
      this.learnunitContainer.removeClass('hidden')
    } else { 
      // console.log("Sorry, no learnunit")
      this.learnunitContainer.addClass('hidden')
      this.journeyContainer.removeClass('hidden')
    }
  }

  /**
   * Displays an event by checking if there is a referenced learnunit target. 
   * If there is, it calls the "Learnunit#show_event_type" reflex with the link element as the argument and hides the journey container while showing the learnunit container. 
   * If there is no learnunit target, it hides the learnunit container and shows the journey container.
   *
   * @param {Object} event - The event to be displayed.
   */
  display_event(event) {
    if (this.hasLearnunitTarget) { // we have to call the referenced Reflex
      var linkElement = this.learnunitTarget
      this.stimulate(`Learnunit#show_event_type`, linkElement).then(() => {
        // this.logInfo('Hello after load')
        // this.create_authenticy_token_for('#event_form')
        // this.reset_csrf_token()
      })
      this.journeyContainer.addClass('hidden')
      this.learnunitContainer.removeClass('hidden')
    } else { 
      // console.log("Sorry, no learnunit")
      this.learnunitContainer.addClass('hidden')
      this.journeyContainer.removeClass('hidden')
    }
  }

  ////
  // hide learn_unit container and show journey container
  ////
  hideLearnUnitContainer() {
    // console.log("show extra information")
    this.learnunitContainer.addClass('hidden')
    this.journeyContainer.removeClass('hidden')
  }

  ///
  // claims the given learn unit and stimulates fitting Reflex as well as it hides the learn_unit container afterwards
  ///
  claimUnit(event) {
    // console.log("claimUnit called")
    const $target = $(event.currentTarget);
    const self = this
    const learnUnitId = $target.data('learnunit-id')
    const journeyId = $target.data('journey-id')
    // console.log("journeyId: ", journeyId)
    // console.log("learnUnitId: ", learnUnitId)

    this.stimulate("LearnunitReflex#claim_unit", journeyId, learnUnitId).then(() => {
      // console.log("claimed the unit");
      self.hideLearnUnitContainer()
    });

  }

  ///
  // opens the learn_unit in the editor frame
  ///
  open(event) {
    // console.log("opening learn unit...")
    /// first we have to close the pseudo modal used for learntype dependent configuration in case it is opened
    $("#learn_units_form_pseudo_modal").addClass("d-none");
    $('#learn_units_form').show();

    let $target = $(event.currentTarget);
    $("#learn_units_form form .slide").removeClass("slide-current");
    
    $("#learn_path .state").removeClass("selected");
    
    $($target).find(".state").addClass("selected");
    
    let id = $target.data("id"),
    type = $target.data("type");
    
    console.log(`open learn unit with id: ${id}`)
    $(`#${type}_${id}_form`).addClass("slide-current");
    $(document).one("show-element", `#${type}_${id}_form`, function () {
      $(this).addClass("slide-current");
    });

    /// also set current learn_unit as hidden field on form and in url
    let form = $('#journey_form');
    const fieldName = "current_learn_unit" 
    const currentIdField = form.find(`input[name=${fieldName}]`)
    // console.log("we have a current field: ", currentIdField)
    if(currentIdField.length > 0) {
      currentIdField.attr('value', id)
    } else {
      let hiddenField = document.createElement('input');
      $(hiddenField).attr({
        type: 'hidden',
        value: id,
        name: fieldName
      });
      $(hiddenField).appendTo(form);  
    }

    // also expand the phase the learn unit is in
    const $phase = $target.closest('.phase')
    const phaseId = $phase.data('phase-id')
    const $phaseBox = $phase.prev();
    // console.log("target: ", $target)
    // console.log("phaseBox: ", $phaseBox)
    const $phaseLine = $phaseBox.closest(".phase-line-container")
    $phaseLine.find(".phase, .phase-box").removeClass('expanded')
    $phase.addClass("expanded")
    $phaseBox.addClass("expanded")

    // set in url
    this.param.updateParams("learn_unit_id", id, false);
    this.param.updateParams("phase_id", phaseId, false);
    this.showForm()
  }

  ///
  // opens the journey_builder_form_frame turbo-frame in the left side frame to present extra input and hides the default journey form 
  ///
  hideForm(event) {
    $('#journey_form').hide()
    $('#journey_builder_form_frame').show()

    // prevent the expand controller on phasese to expand/collapse
    const $target = event.currentTarget
    const $phaseBox = $target.closest(".phase-box")
    console.log("phaseBox: ", $phaseBox)
    $($phaseBox).addClass('fixed')
  }

  ///
  // hides the journey_builder_form_frame turbo-frame in the left side frame and opens the default journey form 
  ///
  showForm() {
    $('#journey_builder_form_frame').hide()
    $('#journey_form').show()
  }

  unfixExpandable(event) {
    event.preventDefault()
    // reenable the expand controller on phases to expand/collapse
    $("#learn_path .phase-box").removeClass('fixed')
    // $('#journey_builder_form_frame').addClass('animate__animated animate__rubberBand')
    event.detail.resume()

  }
}
